<template>
  <div class='bow-login-main-wrapper'>
    <div class='bow-login-sub-div-wrapper'>
      <div class='form-wrapper'>
        <div class='login-text-wrapper'>
          <span class='login-text'>BOW Login</span>
        </div>
        <div>
          <a-form
            layout='inline'
            style='display: contents'
            :model='dynamicValidateForm'
            @finish='onBowLogin'
          >
            <a-form-item
              style='margin: 20px 0px'
              name='clientId'
              :rules="[
                {
                  required: true,
                  message: 'Please input Client ID',
                },
              ]"
            >
              <a-input
                v-model:value='dynamicValidateForm.clientId'
                placeholder='Client ID'
                style='height: 40px; border-radius: 6px'
              >
                <template #prefix><UserOutlined /></template>
              </a-input>
            </a-form-item>
            <a-form-item
              name='password'
              :rules="[
                {
                  required: true,
                  message: 'Please input your Password',
                },
              ]"
              style='margin: 20px 0px'
            >
              <a-input-password
                type='password'
                v-model:value='dynamicValidateForm.password'
                placeholder='Password'
                style='height: 40px; border-radius: 6px'
              >
                <template #prefix><LockOutlined /></template>
              </a-input-password>
            </a-form-item>
            <a-form-item class='sign-in-btn-wrapper'>
              <a-button type='primary' html-type='submit'> Sign In </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent } from '@vue';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import services from '../../../services/apis';

export default {
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const route = useRouter();
    const store = useStore();
    const dynamicValidateForm = reactive({
      clientId: '',
      password: '',
    });

    const onBowLogin = (values) => {
      services
        .bowUserLogin(values)
        .then((response) => {
          route.push('/admin/bow-verifyOtp');
          store.dispatch('onboardingStore/updateBowResponse', response.data);
          message.success(response.data.message, 2);
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 2);
        });
    };

    return {
      onBowLogin,
      dynamicValidateForm,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/BowLogin.scss';
</style>
